
import { Action } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormSubmit } from "@/components/forms";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { bus } from "@/main";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormSubmit,
    },
})
export default class UploadWhiseModal extends Mixins(HasFormErrors) {
    @Action("activity/publishMedia") publishMedia!: (payload: { id: number; document_ids: number[] }) => Promise<void>;

    show: boolean = false;

    activityId: number | null = null;

    documents: Document[] = [];

    mounted() {
        bus.$off("show-upload-whise");
        bus.$on("show-upload-whise", (activityId: number, documents: Document[]) => {
            this.show = true;

            this.activityId = activityId;
            this.documents = documents;
        });
    }

    async submit(form: FormClass) {
        if (!this.activityId) {
            return;
        }

        try {
            await this.publishMedia({ id: this.activityId, document_ids: this.documents.map((d) => d.id) });
	    
            this.handleClose();

            this.$toast.open({ message: this.$t("views.documents.create_success") as string, type: "success", position: "bottom-left" });
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
