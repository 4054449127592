import Vue from "vue";
import Component from "vue-class-component";
import { FormError } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";

@Component({
    components: {
        FormError,
    },
})
export class HasFormErrors extends Vue {
    errorResponse: ErrorResponse = {
        errors: [],
        status: 0,
    };

    formatErrors = formatErrors;

    clearErrors() {
        this.errorResponse = {
            errors: [],
            status: 0,
        };
    }
}
